














































































import { defineComponent, reactive } from "@vue/composition-api";
import { ResidenListVM } from "../models/residenList";
import useInfiniteScrollList from "@/apps/core/modules/useInfiniteScrollList";
import useListFilter from "@/apps/core/modules/useListFilter";
import { GenericListAPI } from "@/apps/core/api/list";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";

export default defineComponent({
  name: "ResidenList",
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
    ListFilter: () => import("@/apps/core/components/ListFilter.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const listVM = new ResidenListVM();
    const angkatanOption: Record<string, any> = {
      field: "angkatan",
      value: "",
      valueMap: { "": "Angkatan" },
    };

    const filterButtonConfigs: Record<string, any> = reactive([angkatanOption]);
    const getAngkatanOptions = async () => {
      const api = new GenericListAPI("/programstudi/angkatan/options/");
      const optionArr = await api.fetch();
      const options = optionArr.reduce((preVal, curVal) => {
        preVal[curVal.id] = toUserDateFormat(curVal.nama);
        return preVal;
      }, {});
      // https://vuejs.org/v2/guide/reactivity.html#For-Objects
      angkatanOption.valueMap = Object.assign(
        {},
        angkatanOption.valueMap,
        options
      );
    };
    getAngkatanOptions();

    return {
      // Data
      filterButtonConfigs,

      // Composition
      ...useInfiniteScrollList(listVM),
      ...useListFilter(),
    };
  },
});
