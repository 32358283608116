




















































































































import { defineComponent, ref } from "@vue/composition-api";
import PencapaianExcel from "../modules/pencapaianExcel";
import ResidenVM from "../models/residen";
import router from "@/router";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";
import useViewModel from "@/apps/core/modules/useViewModel";

export default defineComponent({
  name: "ResidenDetail",
  components: {
    Avatar: () => import("@/apps/core/components/Avatar.vue"),
    CardInfo: () => import("@/apps/core/components/CardInfo.vue"),
    ContextMenu: () => import("@/apps/core/components/ContextMenu.vue"),
    DatePicker: () => import("@/apps/core/components/DatePicker.vue"),
    DetailField: () => import("@/apps/core/components/DetailField.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const paramsId = router.currentRoute.params.id;
    const residenVM = new ResidenVM();
    const { viewModelRef, instance } = useViewModel(paramsId, residenVM);
    const filterMap: Record<string, string> = {
      hasil: "Lulus",
      residenId: paramsId,
    };
    const mulai = ref(null);
    const hingga = ref(null);

    const apiUrl = `${residenVM.api.getAPIUrl()}${paramsId}/`;
    const pencExcel = new PencapaianExcel(apiUrl);

    const exportData = () => {
      pencExcel.exportFile(mulai.value, hingga.value);
    };

    return {
      // Data
      filterMap,
      hingga,
      mulai,
      residen: instance,
      viewModelRef,

      // Method
      exportData,
      toUserDateFormat,
    };
  },
});
