import { Anggota } from "./anggota";
import AnggotaFM from "./anggotaForm";
import { computed, ComputedRef, watch } from "@vue/composition-api";
import DokterForm from "./dokterForm";
import { Residen, ResidenAPI } from "./residen";
import { SaveContext } from "@/apps/core/models/formModel";

export default class ResidenFM extends DokterForm {
  constructor(
    requiredFields: Array<string>,
    nonRequiredFields: Array<string>,
    anggotaForm: AnggotaFM
  ) {
    super(
      new ResidenAPI(),
      new Residen(),
      requiredFields,
      nonRequiredFields,
      anggotaForm
    );
  }

  async fetch(id: string, params = {} as Record<string, string>) {
    // exception sudah dihandle
    try {
      const record = await this.api.fetch(id, params);
      this.instance.loadData(record);
      this.initState = Object.assign({}, this.instance);
      this.validity.validated = true;
      this.validity.edited = false;
      this.anggotaForm.instance.loadData(record.anggota);
      this.anggotaForm.initState = Object.assign({}, this.anggotaForm.instance);
      this.anggotaForm.validity.validated = true;
      this.anggotaForm.validity.edited = false;
      this.anggotaForm.resetErrorMap();
    } catch (error) {
      // console.log(error);
    }
    this.resetErrorMap();

    // ditambahkan watch untuk kasus nested
    // perlu watch ketika selesai loadData anggota
    // supaya tidak perlu setEdited ketika pertama kali load
    watch(this.anggotaForm.instance, () => {
      if (!this.anggotaForm.validity.edited) {
        this.anggotaForm.setEdited(true);
        this.setEdited(true);
      }
    });
  }

  updateErrorMap(errorMap: Record<string, any>) {
    let anggotaErr: Record<string, any>;
    if (errorMap.anggota) {
      anggotaErr = errorMap.anggota;
      if (anggotaErr.user) {
        anggotaErr.email = anggotaErr.user.username;
        delete anggotaErr.user;
      }
      delete errorMap.anggota;
      this.anggotaForm.updateErrorMap(anggotaErr);
    }
    super.updateErrorMap(errorMap);
  }

  getIsValid(): ComputedRef<boolean> {
    return computed(
      () =>
        this.validity.validated &&
        this.anggotaForm.validity.validated &&
        (this.validity.edited || this.anggotaForm.validity.edited)
    );
  }

  async save(
    context: SaveContext = SaveContext.Create,
    autoGoBack = true,
    commit = true
  ) {
    const anggotaInstance = this.anggotaForm.instance;
    (this.instance as Residen).anggota = anggotaInstance as Anggota;
    await super.save(context, autoGoBack, commit);
  }
}
