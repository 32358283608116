import Excel, { Workbook } from "exceljs";
import ExcelBase from "@/apps/core/modules/excelBase";
import axios from "axios";
import { ToastProgrammatic as Toast } from "buefy";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";
import { isResiden } from "@/apps/accounts/modules/store";

class PencapaianExcel extends ExcelBase {
  nilaiSummary: Array<Record<string, any>>;
  nilaiJsonArr: Array<Record<string, any>>;
  pspdJsonArr: Array<Record<string, any>>;
  pencapaian: Record<string, any>;

  constructor(apiUrl: string) {
    super(apiUrl);
    this.nilaiSummary = [];
    this.pencapaian = {};
    this.nilaiJsonArr = []; // reset ketika file null
    this.pspdJsonArr = []; // reset ketika file null
  }

  setPencapaian(pencapaian: Record<string, any>) {
    this.pencapaian = pencapaian;
  }

  exportFile(mulai: string | null, hingga: string | null) {
    this.reset();
    axios
      .get(this.apiUrl, {
        params: { download: "xlsx", mulai: mulai, hingga: hingga },
      })
      .then((response) => {
        this._writeToExcel(response.data.data, mulai, hingga);
      })
      .catch(() => {
        this.reset();
        Toast.open("File gagal di download.");
      });
  }

  createDataNilai(
    data: Record<string, any>,
    workbook: Workbook,
    mulai: string | null,
    hingga: string | null
  ) {
    const dataSet = data["data_set"];
    const nilaiWs = workbook.addWorksheet("Nilai");
    const cellA2 = nilaiWs.getCell("A2");
    const cellA3 = nilaiWs.getCell("A3");
    const cellA4 = nilaiWs.getCell("A4");

    cellA2.value = "UNIVERSITAS PADJADJARAN";
    cellA2.style = { font: { size: 20, bold: true } };
    nilaiWs.getRow(2).height = 27;
    const subJudul = `${data.residen} ${data.npm}`;
    cellA3.value = subJudul;
    cellA3.style = { font: { size: 16 } };
    nilaiWs.getRow(3).height = 18;
    if (mulai !== null && hingga === null) {
      cellA4.value = `Periode mulai: ${toUserDateFormat(mulai)}`;
    } else if (mulai === null && hingga !== null) {
      cellA4.value = `Periode hingga: ${toUserDateFormat(hingga)}`;
    } else if (mulai !== null && hingga !== null) {
      cellA4.value = `Periode : ${toUserDateFormat(
        mulai
      )} s/d ${toUserDateFormat(hingga)}`;
    }

    const colWMap = {
      A: 5,
      B: 30,
      C: 15,
      D: 15,
      E: 15,
      F: 15,
      G: 30,
      H: 30,
      I: 30,
    };
    for (const [col, width] of Object.entries(colWMap)) {
      nilaiWs.getColumn(col).width = width;
    }

    let pspdStartRowNum = 6;
    const pspdHeaderRowCount = 2;
    let pspdHeaderColCount = 9;

    const rowDataKi = [];
    let numKi = 0;
    for (const ki of dataSet["nilai_ki"]) {
      numKi++;
      const kiData = [
        numKi,
        ki.judul,
        toUserDateFormat(ki.tanggal),
        toUserDateFormat(ki.tanggal_maju),
        ki.jenis,
        ki.kegiatan__nama];

      if (!isResiden.value) {
        kiData.push(ki.nilai);
      }

      kiData.concat([
        ki.penguji.join(", "),
        ki.pembimbing.join(", ")]);

      rowDataKi.push(kiData);
    }

    const kiColMap = {
      "A": "No",
      "B": "Judul",
      "C": "Tanggal",
      "D": "Tanggal Maju",
      "E": "Jenis",
      "F": "Kegiatan",
    } as Record<string, string>;

    if (!isResiden.value) {
      kiColMap["G"] = "Nilai";
      kiColMap["H"] = "Penguji";
      kiColMap["I"] = "Pembimbing";
      // merge a range of cells
      nilaiWs.mergeCells("A6:I6");
    } else {
      kiColMap["G"] = "Penguji";
      kiColMap["H"] = "Pembimbing";
      pspdHeaderColCount -= 1;
      // merge a range of cells
      nilaiWs.mergeCells("A6:H6");
    }

    for (const rowNum of Array(pspdHeaderRowCount).keys()) {
      const num = rowNum + pspdStartRowNum;
      nilaiWs.getRow(num).alignment = {
        wrapText: true,
        horizontal: "center",
      };
      for (const [col, val] of Object.entries(kiColMap)) {
        nilaiWs.getCell(`${col}${num}`).value = val;
        nilaiWs.getCell(`${col}${num}`).fill = {
          type: "pattern",
          pattern: "lightGray",
        };
      }
    }

    for (const rowNum of Array(pspdHeaderRowCount + rowDataKi.length).keys()) {
      for (const cidx of Array(pspdHeaderColCount).keys()) {
        if (rowNum < rowDataKi.length) {
          nilaiWs.getCell(
            rowNum + pspdStartRowNum + pspdHeaderRowCount,
            cidx + 1
          ).value = rowDataKi[rowNum][cidx];
        }
        nilaiWs.getCell(rowNum + pspdStartRowNum, cidx + 1).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
    }
    nilaiWs.getCell("A6").value = "Data Karya Ilmiah";

    const sidx =
      pspdStartRowNum + pspdHeaderRowCount + dataSet["nilai_ki"].length + 3;

    const rowDataUjian = [];
    let numUjian = 0;
    for (const ujian of dataSet["nilai_ujian"]) {
      numUjian++;
      const ujianData = [
        numUjian,
        ujian.judul,
        ujian.ujian,
        toUserDateFormat(ujian.rencana_ujian),
        toUserDateFormat(ujian.tanggal_ujian),
      ]
      if (!isResiden.value) {
        ujianData.push(ujian.nilai);
      }

      ujianData.push(ujian.penguji.join(", "));
      rowDataUjian.push(ujianData);
    }

    const ujianColMap = {
      A: "No",
      B: "Judul",
      C: "Ujian",
      D: "Rencana Ujian",
      E: "Tanggal Ujian"
    } as Record<string, string>;
    pspdStartRowNum = sidx;
    pspdHeaderColCount = 7;

    if (!isResiden.value) {
      ujianColMap["F"] = "Nilai";
      ujianColMap["G"] = "Penguji";
      // merge a range of cells
      nilaiWs.mergeCells(`A${sidx}:G${sidx}`);
    } else {
      ujianColMap["F"] = "Penguji";
      pspdHeaderColCount -= 1;
      // merge a range of cells
      nilaiWs.mergeCells(`A${sidx}:F${sidx}`);
    }

    for (const rowNum of Array(
      pspdHeaderRowCount + rowDataUjian.length
    ).keys()) {
      for (const cidx of Array(pspdHeaderColCount).keys()) {
        if (rowNum < rowDataUjian.length) {
          nilaiWs.getCell(
            rowNum + pspdStartRowNum + pspdHeaderRowCount,
            cidx + 1
          ).value = rowDataUjian[rowNum][cidx];
        }
        nilaiWs.getCell(rowNum + pspdStartRowNum, cidx + 1).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
    }

    for (const rowNum of Array(pspdHeaderRowCount).keys()) {
      // worksheet.getRow(row_num).font = { bold: true };
      const num = rowNum + pspdStartRowNum;
      nilaiWs.getRow(num).alignment = {
        wrapText: true,
        horizontal: "center",
      };
      for (const [col, val] of Object.entries(ujianColMap)) {
        nilaiWs.getCell(`${col}${num}`).value = val;
        nilaiWs.getCell(`${col}${num}`).fill = {
          type: "pattern",
          pattern: "lightGray",
        };
      }
    }
    nilaiWs.getCell(`A${sidx}`).value = "Data Ujian";
  }

  createDataPencapaian(
    data: Record<string, any>,
    workbook: Workbook,
    mulai: string | null,
    hingga: string | null
  ) {
    const dataSet = data["data_set"] as Record<string, Record<string, any>>;
    const pencWs = workbook.addWorksheet("Pencapaian");
    const cellA2 = pencWs.getCell("A2");
    const cellA3 = pencWs.getCell("A3");
    const cellA4 = pencWs.getCell("A4");

    cellA2.value = "UNIVERSITAS PADJADJARAN";
    cellA2.style = { font: { size: 20, bold: true } };
    pencWs.getRow(2).height = 27;
    const subJudul = `${data.residen} ${data.npm}`;
    cellA3.value = subJudul;
    cellA3.style = { font: { size: 16 } };
    pencWs.getRow(3).height = 18;
    if (mulai !== null && hingga === null) {
      cellA4.value = `Periode mulai: ${toUserDateFormat(mulai)}`;
    } else if (mulai === null && hingga !== null) {
      cellA4.value = `Periode hingga: ${toUserDateFormat(hingga)}`;
    } else if (mulai !== null && hingga !== null) {
      cellA4.value = `Periode : ${toUserDateFormat(
        mulai
      )} s/d ${toUserDateFormat(hingga)}`;
    }

    const colWMap = {
      A: 5,
      B: 30,
      C: 15,
      D: 15,
      E: 15,
      F: 15,
      G: 30,
      H: 30,
      I: 30,
    };
    for (const [col, width] of Object.entries(colWMap)) {
      pencWs.getColumn(col).width = width;
    }

    let pspdStartRowNum = 6;
    const pspdHeaderRowCount = 2;
    let pspdHeaderColCount = 3;
    // merge a range of cells
    pencWs.mergeCells("A6:C6");

    const rowDataDiag = [];
    let numPencDiag = 0;
    for (const [diagnosis, dataDiag] of Object.entries(
      dataSet["penc_diag_dict"]
    )) {
      numPencDiag++;
      rowDataDiag.push([
        numPencDiag,
        diagnosis,
        `${dataDiag.pencapaian}/${dataDiag.target}`,
      ]);
    }

    const diagColMap = {
      A: "No",
      B: "Kasus/penyakit",
      C: "Target",
    };

    for (const rowNum of Array(pspdHeaderRowCount).keys()) {
      const num = rowNum + pspdStartRowNum;
      pencWs.getRow(num).alignment = {
        wrapText: true,
        horizontal: "center",
      };
      for (const [col, val] of Object.entries(diagColMap)) {
        pencWs.getCell(`${col}${num}`).value = val;
        pencWs.getCell(`${col}${num}`).fill = {
          type: "pattern",
          pattern: "lightGray",
        };
      }
    }

    for (const rowNum of Array(
      pspdHeaderRowCount + rowDataDiag.length
    ).keys()) {
      for (const cidx of Array(pspdHeaderColCount).keys()) {
        if (rowNum < rowDataDiag.length) {
          pencWs.getCell(
            rowNum + pspdStartRowNum + pspdHeaderRowCount,
            cidx + 1
          ).value = rowDataDiag[rowNum][cidx];
        }
        pencWs.getCell(rowNum + pspdStartRowNum, cidx + 1).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
    }
    pencWs.getCell("A6").value = "Data Pencapaian Kasus/Penyakit";

    const sidx = pspdStartRowNum + pspdHeaderRowCount + rowDataDiag.length + 3;
    // merge a range of cells
    pencWs.mergeCells(`A${sidx}:E${sidx}`);

    const rowDataKet = [];
    let numKet = 0;
    for (const [ket, dataKet] of Object.entries(dataSet["penc_ket_dict"])) {
      numKet++;
      rowDataKet.push([
        numKet,
        ket,
        `${dataKet.tahap1}/${dataKet.target1}`,
        `${dataKet.tahap2}/${dataKet.target2}`,
        `${dataKet.tahap3}/${dataKet.target3}`,
      ]);
    }

    const ketColMap = {
      A: "No",
      B: "Keterampilan Klinis",
      C: "Tahap 1",
      D: "Tahap 2",
      E: "Tahap 3",
    };
    pspdStartRowNum = sidx;
    pspdHeaderColCount = 5;

    for (const rowNum of Array(pspdHeaderRowCount + rowDataKet.length).keys()) {
      for (const cidx of Array(pspdHeaderColCount).keys()) {
        if (rowNum < rowDataKet.length) {
          pencWs.getCell(
            rowNum + pspdStartRowNum + pspdHeaderRowCount,
            cidx + 1
          ).value = rowDataKet[rowNum][cidx];
        }
        pencWs.getCell(rowNum + pspdStartRowNum, cidx + 1).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
    }

    for (const rowNum of Array(pspdHeaderRowCount).keys()) {
      // worksheet.getRow(row_num).font = { bold: true };
      const num = rowNum + pspdStartRowNum;
      pencWs.getRow(num).alignment = {
        wrapText: true,
        horizontal: "center",
      };
      for (const [col, val] of Object.entries(ketColMap)) {
        pencWs.getCell(`${col}${num}`).value = val;
        pencWs.getCell(`${col}${num}`).fill = {
          type: "pattern",
          pattern: "lightGray",
        };
      }
    }
    pencWs.getCell(`A${sidx}`).value = "Data Pencapaian Keterampilan";
  }

  async _writeToExcel(
    data: Record<string, any>,
    mulai: string | null,
    hingga: string | null
  ) {
    const workbook = new Excel.Workbook();
    this.createDataNilai(data, workbook, mulai, hingga);
    this.createDataPencapaian(data, workbook, mulai, hingga);

    const buffer = await workbook.xlsx.writeBuffer();
    const url = window.URL.createObjectURL(
      new Blob([buffer], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    this.filename = `${data.residen}_${this.getCurrentDatetime()}.xlsx`
      .split(" ")
      .join("_");
    link.setAttribute("download", this.filename); //or any other extension
    // link.download = this.filename;
    document.body.appendChild(link);
    link.click();
    // window.URL.revokeObjectURL(url);
  }

  getCurrentDatetime() {
    const currentdt = new Date();
    const mm = currentdt.getMonth() + 1; // getMonth() is zero-based
    const dd = currentdt.getDate();
    const hh = currentdt.getHours();
    const mi = currentdt.getMinutes();

    return [
      currentdt.getFullYear(),
      (mm > 9 ? "" : "0") + mm,
      (dd > 9 ? "" : "0") + dd,
      (hh > 9 ? "" : "0") + hh,
      (mi > 9 ? "" : "0") + mi,
    ].join("");
  }

  reset() {
    super.reset();
    this.nilaiJsonArr = []; // reset ketika file null
    this.pspdJsonArr = []; // reset ketika file null
    // this.stase = null;
    this.nilaiSummary = [];
  }
}

export default PencapaianExcel;
