































import { defineComponent } from "@vue/composition-api";
import AnggotaFM from "../models/anggotaForm";
import ResidenFM from "../models/residenForm";
import router from "@/router";
import useFormModel from "@/apps/core/modules/useFormModel";


export default defineComponent({
  name: "ResidenForm",
  components: {
    AnggotaForm: () => import("@/apps/anggota/views/AnggotaForm.vue"),
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue"),
  },
  props: {
    headerText: { type: String, required: true },
  },
  setup() {
    const paramsId = router.currentRoute.params.id;
    const requiredFields = ["nama", "gender", "email"];
    const nonRequiredFields = ["inisial", "noHp", "alamat", "catatan"];
    const anggotaForm = new AnggotaFM(requiredFields, nonRequiredFields);
    const form = new ResidenFM(
      ["npm", "angkatan"],
      ["anggota"],
      anggotaForm
    );
    const composition = useFormModel(form, router.currentRoute.params.id);
    const residen = composition.instance;

    return {
      // Data
      form,
      isCreate: !paramsId,
      residen,

      // Composition
      ...composition,
    };
  },
});
