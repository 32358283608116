import { Residen, ResidenAPI, ResidenOptions, ResidenOptionsAPI } from "./residen";
import ListAPI from "@/apps/core/api/list";
import ViewListModel from "@/apps/core/models/viewListModel";

export class ResidenListAPI extends ListAPI {
  constructor() {
    super(ResidenAPI.modelPath);
  }
}

export class ResidenListVM extends ViewListModel {
  constructor() {
    super(new ResidenListAPI());
  }

  getInstance(json: Record<string, any>) {
    const residen = new Residen();
    residen.loadData(json);
    return residen;
  }
}


export class ResidenOptionsListAPI extends ListAPI {
  constructor() {
    super(ResidenOptionsAPI.modelPath);
  }
}

export class ResidenOptionsListVM extends ViewListModel {
  constructor() {
    super(new ResidenOptionsListAPI());
  }

  getInstance(json: Record<string, any>) {
    const residen = new ResidenOptions();
    residen.loadData(json);
    return residen;
  }
}
