import { API } from "@/apps/core/api";
import FormModel from "@/apps/core/models/formModel";
import PlainModel from "@/apps/core/models/plainModel";
import { DokterBase } from "./anggota";
import { setRefreshRequest } from "../modules/store";
import AnggotaFM from "./anggotaForm";

export default class DokterForm extends FormModel {
  anggotaForm: AnggotaFM;

  constructor(
    dokterAPI: API,
    dokter: PlainModel,
    requiredFields: Array<string>,
    nonRequiredFields: Array<string>,
    anggotaForm: AnggotaFM
  ) {
    super(
      dokterAPI,
      dokter,
      requiredFields,
      nonRequiredFields,
      setRefreshRequest
    );
    this.anggotaForm = anggotaForm;
  }

  getPayload(): Record<string, any> {
    const payload = super.getPayload();
    const dokter = this.instance as DokterBase;
    if (dokter.anggota) {
      const anggota = this.anggotaForm.getPayload();
      if (anggota.email !== undefined)
        anggota.user = { username: anggota.email };
      delete anggota.email;
      payload.anggota = anggota;
    }
    return payload;
  }
}
